.logo-container {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: center;
}
.container-nacional {
  height: 5000px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  background-image: url('../assets/fundo.svg');
  background-repeat: no-repeat;
  background-size: cover; /* Ajuste o tamanho da imagem para cobrir todo o dashboard */
}
.container-banco {
  justify-content: center;
  heigth: auto;
  margin: 250px;
  width: auto;
  margin-top: 10px;
}
.card-body {
  line-height: 230%;
  margin-top: 15px;
}
.mb-4 {
  margin-bottom: 2.5rem !important;
}
.input-groupb > .form-control,
.input-groupb > .form-floating,
.input-groupb > .form-select {
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
  width: 100%;
}
.input-groupb {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.title {
  text-align: left;
  margin-bottom: 10px;
}

.form-control {
  width: 100%;
  margin-bottom: 7px;
}

.btn-primary {
  margin-left: 10px;
}

.search-results {
  margin-top: 20px;
  width: auto;
  height: 200px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  background: floralwhite;
  flex-direction: column;
  height: 100%;
  margin-bottom: 30px;
  max-width: 100%;
  overflow: hidden;
  transition: box-shadow 0.3s;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card:hover .card-img-top {
  transform: scale(1.05);
}

.rounded-circle {
  border-radius: 50% !important;
}

.card-img-top {
  width: 54%;
  height: 260px; /* Defina a altura desejada para a imagem */
  object-fit: scale-down;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transition: transform 0.3s;
}

.card-body {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-text {
  font-size: 14px;
  margin-bottom: 5px;
}

.col-12.text-center p {
  color: #999;
  font-style: italic;
}

@media (min-width: 992px) {
  .col-lg-4,
  .col-md-6 {
    padding-left: 15px; /* Ajuste o espa�amento � esquerda */
    padding-right: 15px; /* Ajuste o espa�amento � direita */
  }
}

.row-b {
  align-content: space-between;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
}
.card-img-container {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.card-img-container .card-img-top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 100%;
  left: 0;
  object-fit: contain;
  top: 0;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition:
    transform 0.3s,
    -webkit-transform 0.3s;
  width: 100%;
}

.card-img-container .card-img-top.secondary {
  opacity: 0;
  transform: scale(0.8);
}

.card:hover .card-img-container .card-img-top.secondary {
  opacity: 1;
  transform: scale(1);
}

.hovered .card-img-container .card-img-top {
  transform: scale(1.2);
}
/* Estilo para o modal */
.modalb {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

.modalb-content {
  position: relative;
  max-width: 800px;
  width: 100%;
  background-color: #fff;
  padding: 20px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #555;
  cursor: pointer;
}

.modalb-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.modalb-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.modalb-photo {
  width: 200px;
  height: auto;
  border-radius: 8px;
}
.btn-print {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.btn-print i {
  margin-right: 0.5rem;
}

.btn-print:hover {
  background-color: #0056b3;
}
