.dashboard {
  display: flex;
  flex-direction: row;
  transition: margin-left 0.3s ease;
}
.sidebar-menu-item:hover {
  background-color: #f0f0f0;
  transform: translateX(3px); /* Slight horizontal shift on hover */
}
.sidebar-user {
  margin: 0;
  padding: 20px;
  width: 150px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  height: 100%;
  overflow: auto;
  border-radius: 8px;
  z-index: 100;
}
.sidebar {
  background-color: #fff0;
  border-radius: 8px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  height: 0%;
  width: max-content;
  left: 20px;
  /* margin-right: 67px; */
  padding: 0px;
  position: fixed;
  top: 15px;
  width: 220px;
  align-items: flex-start;
  justify-content: space-between;
}
.sidebar-minimized + .dashboard {
  margin-left: 80px;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo-spin {
  animation: spin infinite 20s linear;
}

.sidebar-user {
  background-color: #333;
  color: #fff;
  width: 250px;
}

.sidebar-menu,
.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu-item,
.sidebar-item {
  margin-bottom: 10px;
  transition: background-color 0.3s, transform 0.3s; /* Add transition for smooth animations */
}

.sidebar li,
.sidebar-button,
.sidebar-link {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  color: #000;
  transition: color 0.3s, background-color 0.3s;
}

.sidebar li button,
.sidebar li a {
  all: unset;
}

.sidebar li:hover,
.sidebar-button:hover,
.sidebar-link:hover {
  color: #7198ff;
  background-color: #f5f5f5;
}

.sidebar-link.active {
  background-color: #f0f0f0;
  color: #333;
  font-weight: bold;
}

.sidebar-icon,
.logout-button svg {
  margin-right: 10px;
}

.content {
  margin-left: 150px;
  padding: 10px 16px;
}

.user-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.user-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.user-name {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.logout-button {
  background-color: #e74c3c;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  color: #fff;
  align-items: center;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #c0392b;
}

.sidebar-item {
  color: #fff;
  text-decoration: none;
}

.sidebar-item:hover {
  text-decoration: underline;
}

.sidebar-toggle {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 200;
}

.sidebar-minimized {
  width: 80px;
}

.sidebar-label {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  font-size: 14px; /* Adjust font size */
}

.sidebar-minimized .sidebar-label {
  opacity: 0;
  transform: translateX(-20px); /* Move label offscreen when minimized */
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out 0.1s;
}

.minimize-btn {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.minimize-btn:hover {
  background-color: #f5f5f5;
}

@media (max-width: 768px) {
  .dashboard {
    margin-left: 80px;
  }

  .sidebar {
    width: 60px;
    padding: 10px 0;
  }

  .sidebar-label {
    display: none;
  }

  .sidebar-menu-item {
    margin-bottom: 5px;
  }

  .sidebar li {
    padding: 8px;
  }
}
