@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap");

@charset "UTF-8";

.MuiDrawer-paper {
  box-sizing: border-box;
}

.MuiBox-root {
  margin: 0; /* Remover margem do MuiBox-root */
  padding: 0; /* Remover padding do MuiBox-root */
}
.leaflet-popup-content p {
  margin: 0.3em 0;
}
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blinking {
  animation: blink 1s infinite;
}
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blinking {
  animation: blink 1s infinite;
}

.watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: rgba(255, 0, 0, 0.3); /* Red color with transparency */
  text-align: center;
  pointer-events: none;
  user-select: none;
}
.css-g9qiy3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
   
    height: 100vh;
}
.css-8ohbzx {
  position: absolute;
  top: 60%;
  left: 16%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.css-hh09d7-MuiTypography-root {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: red;
  position: absolute;
  top: 70px;
  left: 140px;
  right: 10px;
  font-size: 14px;
  font-weight: 700;
  z-index: 2;
}


body {
  font-family: Montserrat, sans-serif;
  background-color: #f8f9fa;
  box-sizing: border-box;
  font-size: 100%;
  margin: 0;
  padding: 0;
  zoom: 80%
}
.App {
  font-family: sans-serif;
  text-align: initial;
}
.container,
.logo-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
}
.flags-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 20px;
  left: 20px;
}
.flag-imgb {
  width: 3%; /* Tamanho ajustado para melhor visualiza��o */
  height: auto;
}
.flag-img {
  width: 3%; /* Tamanho ajustado para melhor visualiza��o */
  height: auto;
}
.dados-item {
  text-align: center;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 8px;
  flex: 1;
}

.dados-num {
  display: block;
  font-size: 2em;
  margin-bottom: 10px;
}

.dados-label {
  font-size: 1.2em;
  color: #555;
}

.container-main {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 800px;
    min-height: 100vh;
    width: 800px;
    padding: 20px;
    /* margin-bottom: -34px; */
    /* margin-top: -4px; */
    zoom: 120%;
    margin: 0 auto;
}
@media (max-width: 800px) {
  .container-main {
    padding: 10px;
    width: 90%; /* Altera a largura para 90% em telas menores */
    max-width: none; /* Remove a largura m�xima em telas menores */
  }
}

.logo-container {
  flex: 0 0 auto;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-img {
  width: 350px;
  height: 350px;
  animation: rotate-logo 10s linear infinite;
}

.slogan-img {
  width: 400px;
  margin-top: 10px;
}

@keyframes rotate-logo {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-radius: 30px;
  background: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.login-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333;
}

.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-field {
  margin-bottom: 20px;
}

.form-field label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
  color: #555;
}

.form-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
  width: 100%;
}

.login-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.login-button:focus,
.login-button:active {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

button[type="button"] {
  background-color: transparent;
  color: #7f118d;
  font-size: 14px;
  text-decoration: none;
  margin-left: auto;
  padding: 5px 0;
  border: none;
  outline: none;
}

.signup-container button[type="button"] {
  background-color: #f4f4f4;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px 20px;
}

.signup-container button[type="button"]:hover {
  background-color: #e8e8e8;
}

.login-button {
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  background-color: #4285f4;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #357abd;
}

.forgot-password {
  text-align: right;
  font-size: 12px;
  margin-top: 10px;
  color: #888;
  cursor: pointer;
}

.signup-container {
  text-align: center;
  margin-top: 20px;
}

.signup-text {
  font-size: 14px;
  color: #666;
}

.content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  margin-left: 220px;
}

.signup-link {
  color: #4285f4;
  text-decoration: none;
  margin-left: 5px;
  font-weight: 600;
}

.google-login-container {
  margin-top: 20px;
}

.google-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  background-color: #db4437;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
}

.google-login-button:hover {
  background-color: #c5372d;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.google-login-button {
  border-radius: 8px;
}

.google-login-button span {
  margin-left: 10px;
}
.loader {
  border: 4px solid #f3f3f3; /* Cor de fundo */
  border-top: 4px solid #3498db; /* Cor do spinner */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.inputGroup {
  font-family: "Segoe UI", sans-serif;
  margin: 1em 0 1em 0;
  max-width: 190px;
  position: relative;
}

.inputGroup input {
  font-size: 100%;
  padding: 0.8em;
  outline: none;
  border: 2px solid rgb(200, 200, 200);
  background-color: transparent;
  border-radius: 20px;
  width: 150%;
}

.inputGroup label {
  font-size: 100%;
  position: absolute;
  left: 0;
  padding: 0.8em;
  margin-left: 0.5em;
  pointer-events: none;
  transition: all 0.3s ease;
  color: rgb(100, 100, 100);
  top: 1px;
}

.inputGroup :is(input:focus, input:valid) ~ label {
  transform: translateY(-50%) scale(0.9);
  margin: 0em;
  margin-left: 1.3em;
  padding: 0.4em;
  background-color: #ffffff;
}

.inputGroup :is(input:focus, input:valid) {
  border-color: rgb(150, 150, 200);
}
.register-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-modal form {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 80%;
  max-width: 400px;
}

/* Responsividade */

@media screen and (max-width: 800px) {
  .container-main {
    width: 100%;
    flex-direction: column;
  }

  .logo-container {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .logo-img {
    width: 200px;
    height: 200px;
  }

  .slogan-img {
    width: 250px;
  }

  .login-container {
    width: 80%;
  }

  .dados-item {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 500px) {
  .logo-img {
    width: 150px;
    height: 150px;
  }

  .slogan-img {
    width: 200px;
  }

  .login-form,
  .login-buttons {
    flex-direction: column;
  }

  .login-button,
  button[type="button"] {
    width: 100%;
    margin-bottom: 10px;
  }
}
.form_main {
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(241, 241, 241);
  padding: 30px 30px 30px 30px;
  border-radius: 30px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.062);
}

.heading {
  font-size: 2.5em;
  color: #2e2e2e;
  font-weight: 700;
  margin: 15px 0 30px 0;
}

.inputContainer {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputIcon {
  position: absolute;
  left: 10px;
}

.inputField {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgb(173, 173, 173);
  border-radius: 30px;
  margin: 10px 0;
  color: black;
  font-size: 0.8em;
  font-weight: 500;
  box-sizing: border-box;
  padding-left: 30px;
}
.inputField:focus {
  outline: none;
  border-bottom: 2px solid rgb(199, 114, 255);
}

.inputField::placeholder {
  color: rgb(80, 80, 80);
  font-size: 1em;
  font-weight: 500;
}

#button {
  position: relative;
  width: 100%;
  border: 2px solid #8000ff;
  background-color: #8000ff;
  height: 40px;
  color: white;
  font-size: 0.8em;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 30px;
  margin: 10px;
  cursor: pointer;
  overflow: hidden;
}
#button::after {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.253);
  height: 100%;
  width: 150px;
  top: 0;
  left: -200px;
  border-bottom-right-radius: 100px;
  border-top-left-radius: 100px;
  filter: blur(10px);
  transition-duration: 0.5s;
}

#button:hover::after {
  transform: translateX(600px);
  transition-duration: 0.5s;
}
.signupContainer {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.signupContainer p {
  font-size: 0.9em;
  font-weight: 500;
  color: black;
}
.signupContainer a {
  font-size: 0.7em;
  font-weight: 500;
  background-color: #2e2e2e;
  color: white;
  text-decoration: none;
  padding: 8px 15px;
  border-radius: 20px;
}
.button-secondary {
  position: relative;
  width: 80%; /* 80% para torn�-lo um pouco menor que o bot�o principal */
  border: 2px solid #8000ff;
  background-color: #8000ff;
  height: 32px; /* altura ajustada para ser menor */
  color: white;
  font-size: 0.7em;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 30px;
  margin: 10px;
  cursor: pointer;
  overflow: hidden;
}

.button-secondary::after {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.253);
  height: 100%;
  width: 150px;
  top: 0;
  left: -200px;
  border-bottom-right-radius: 100px;
  border-top-left-radius: 100px;
  filter: blur(10px);
  transition-duration: 0.5s;
}

.button-secondary:hover::after {
  transform: translateX(600px);
  transition-duration: 0.5s;
}
.form1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 350px;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  position: relative;
}
.title-app {
  font-size: 28px;
  color: royalblue;
  background-color: #ffffff;
  font-weight: 600;
  letter-spacing: -1px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 30px;
}

.title-app::before {
  width: 18px;
  height: 18px;
  background-color: royalblue;
}

.title-app::after {
  width: 18px;
  height: 18px;
  animation: pulse 1s linear infinite;
}

.message,
.signin {
  color: rgba(88, 87, 87, 0.822);
  font-size: 14px;
}

.signin {
  text-align: center;
}

.signin a {
  color: royalblue;
}

.signin a:hover {
  text-decoration: underline royalblue;
}

.flex {
  display: flex;
  width: 100%;
  gap: 6px;
}

.form1 label {
  position: relative;
}

.form1 label .input {
  width: 100%;
  padding: 10px 10px 20px 10px;
  outline: 0;
  border: 1px solid rgba(105, 105, 105, 0.397);
  border-radius: 10px;
}

.form1 label .input + span {
  position: absolute;
  left: 10px;
  top: 0px;
  color: grey;
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
}

.form1 label .input:placeholder-shown + span {
  top: 15px;
  font-size: 0.9em;
}

.form1 label .input:focus + span,
.form label .input:valid + span {
  top: 0px;
  font-size: 0.7em;
  font-weight: 600;
}

.form1 label .input:valid + span {
  color: green;
}

.submit {
  border: none;
  outline: none;
  background-color: royalblue;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  transform: 0.3s ease;
}

.submit:hover {
  background-color: rgb(56, 90, 194);
  cursor: pointer;
}

@keyframes pulse {
  from {
    transform: scale(0.9);
    opacity: 1;
  }

  to {
    transform: scale(1.8);
    opacity: 0;
  }
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  background: none;
  border: none;
  color: #fff; /* or any color that contrasts with the modal's background */
}
/* Image upload styles */
/* Esconder o input padr�o de arquivo */
.image-upload-input {
  display: none;
}

/* Estilo do bot�o de upload */
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-upload {
  border: 2px solid #2e2e2e;
  color: #2e2e2e;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
/* Estilos para mensagens de sucesso */
.success-message {
  background-color: #4caf50; /* Cor de fundo verde */
  color: #ffffff; /* Cor do texto branco */
  padding: 10px; /* Espa�amento interno */
  border-radius: 4px; /* Cantos arredondados */
  margin-bottom: 20px; /* Espa�amento inferior */
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Sombra suave */
}

.success-message svg {
  fill: #ffffff; /* Cor do �cone branco */
  margin-right: 10px; /* Espa�amento � direita do �cone */
}

/* Estilos para mensagens de erro */
.error-message {
  background-color: #f44336; /* Cor de fundo vermelho */
  color: #ffffff; /* Cor do texto branco */
  padding: 10px; /* Espa�amento interno */
  border-radius: 4px; /* Cantos arredondados */
  margin-bottom: 20px; /* Espa�amento inferior */
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Sombra suave */
}

.error-message svg {
  fill: #ffffff; /* Cor do �cone branco */
  margin-right: 10px; /* Espa�amento � direita do �cone */
}
/* CSS para o modal de termos de uso */
/* CSS para o modal de termos de uso */
.terms-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
}

.terms-modal-content {
  background-color: #fff;
  padding: 3%;
  border-radius: 5px;
  width: 250%;
  max-height: 300vh;
  overflow-y: auto;
  position: relative;
}

/* Estilo para o checkbox */
.terms-checkbox-label {
  display: block;
  margin-top: 10px;
}

.terms-checkbox {
  margin-right: 5px;
}

/* CSS para a classe terms-text */
.terms-text {
  white-space: pre-wrap;
  font-family: Arial, sans-serif;
}

/* Estilo para o bot�o de fechar */
.terms-modal-content button {
  font-size: 90%;
}

/* Media query para telas menores, por exemplo, menos de 768px de largura */
@media (max-width: 768px) {
  .terms-modal {
    width: 95%;
    padding: 5%;
  }

  .terms-modal-content {
    padding: 5%;
  }

  .terms-checkbox-label {
    font-size: 90%;
  }

  .terms-text {
    font-size: 90%;
  }

  .terms-modal-content button {
    font-size: 100%;
  }
}
